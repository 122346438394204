<template>
  <div class="momo-product-item">
    <div class="image">
      <img :src="product.image" />
    </div>
    <div class="right-content">
      <div class="text">{{ product.title }}</div>
      <div class="text">{{ product.secondTitle }}</div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="point">{{ product.point }}點</div>
        <div class="cta-area w-50 d-flex justify-content-center flex-column">
          <div v-if="showDate" class="date">{{ product.date }}</div>
          <div class="cta">
            <img
              v-if="product.status === 'available'"
              src="./images/momo_cta_button_available.png"
              @click="doRedeem(product)"
            />
            <img
              v-if="product.status === 'finish'"
              src="./images/momo_cta_button_finish.png"
            />
            <img
              v-if="product.status === 'point_disable'"
              src="./images/momo_cta_button_point_disable.png"
            />
            <img
              v-if="product.status === 'redeemed'"
              src="./images/momo_cta_button_redeemed.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"


export default {
  props: ["product", "showDate", "fetchGifts"],
  computed: {
    ...mapGetters({
      me: "liffHuggies/me",
    }),
  },
  methods: {
    ...mapActions({
      redeemGiftAction: 'liffHuggies/redeemGift',
    }),
    async doRedeem(product) {
      let isAllUserDataFilled = ['name', 'county', 'district', 'address'].every(key => this.me.extra[key] ?? null)

      if (!isAllUserDataFilled) {
        this.$swal.fire({
          title: "贈品尚未兌換",
          text: "請先填寫完整兌獎資訊，再回來兌換贈品",
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonText: "填寫資料",
        }).then(async (result) => {
          if (result.value) {
            this.$router.push({ name: "LiffHuggiesMomoProfile" });
          }
        })
        return
      }

      this.$swal.fire({
        text: "是否確認要兌換此項贈品？",
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonText: "確認",
      }).then(async (result) => {
        if (!result.value) {
          return
        }
        try {
          this.redeemGiftAction(product)
          this.$swal({
            title: "兌換完成！",
            text: "贈品預計於4週內寄送至會員指定地址",
            icon: "success",
            button: "確定",
          }).then(() => {
            this.fetchGifts()
          })
        } catch (error) {
          this.$swal.fire({
            text: '點數不足',
            type: 'error'
          })
        }
      });
    }
  }
};
</script>

<style lang="scss">
.momo-product-item {
  background: #fff;
  padding: 5px 10px;
  border-radius: 15px;
  border: 2px solid #f5cf7a;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
  height: 110px;

  .badge {
    background: #2a4221;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
    color: #fff;
    padding: initial;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
    padding: 0 2px;
  }

  .right-content {
    width: 60%;
  }

  .image {
    width: 40%;
    height: 100%;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .text {
    font-size: 18px;
    color: #474747;
    line-height: 1.1;
  }

  .point {
    font-size: 25px;
    color: #2a4221;
    font-weight: bold;
    line-height: 1;
  }

  .cta-area {
    width: 45%;
  }

  .cta {
    img {
      width: 100%;
    }
  }

  .date {
    color: #2a4221;
    font-size: 12px;
  }
}
</style>
