<template>
  <div class="position-relative">
    <MomoNav>
      <div class="nav-item">
        <a href="#">
          <img class="" src="./images/momo_button_redeem_active.png" />
        </a>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoRedeemRecord' }">
          <img class="" src="./images/momo_button_redeem_record.png" />
        </router-link>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoPointRecord' }">
          <img class="" src="./images/momo_button_point_record.png" />
        </router-link>
      </div>
    </MomoNav>
    <div>
      <div>
        <router-link :to="{ name: 'LiffHuggiesMomoMemberIndex' }">
          <img class="home" src="./images/momo_profile_back_btn.png" />
        </router-link>
        <img class="layoutMomo-title" src="./images/momo_title_redeem.png" />
      </div>
      <div class="list mt-3">
        <MomoProductItem
          v-for="gift in gifts"
          :product="gift"
          :key="gift.id"
          :fetchGifts="fetchGifts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MomoNav from "./MomoNav";
import MomoProductItem from "./MomoProductItem";
import Huggies from "@/apis/liff/Huggies";
import withFetcher from "@/mixins/liff/withFetcher";
import { mapGetters, mapActions } from "vuex";

const STATUS = {
  AVAILABLE: "available",
  FINISH: "finish",
  NOT_ENOUGH_POINT: "point_disable",
};

export default {
  mixins: [withFetcher],
  components: {
    MomoNav,
    MomoProductItem,
  },
  data() {
    return {
      gifts: [],
    };
  },
  computed: {
    ...mapGetters({
      availablePoints: "liffHuggies/availablePoints",
    }),
  },
  methods: {
    ...mapActions({
      fetchAvailablePoints: "liffHuggies/fetchAvailablePoints",
    }),
    async fetch() {
      await this.fetchAvailablePoints();
      await this.fetchGifts();
    },
    async fetchGifts() {
      let gifts = await Huggies.getInstance().listGifts();
      if (!gifts || !gifts.length) {
        this.gifts = [];
        return;
      }

      this.gifts = gifts.map((g) => {
        let status = STATUS.AVAILABLE;
        if (g.point_need > this.availablePoints) {
          status = STATUS.NOT_ENOUGH_POINT;
        }

        if (g.quantity <= 0) {
          status = STATUS.FINISH;
        }

        return {
          id: g.id,
          title: g.name,
          secondTitle: g.data?.sub_title || "",
          badge: g.data?.badge || "",
          point: g.point_need,
          image: g.image_url || "",
          status,
        };
      });
    },
  },
};
</script>

<style lang="scss">
.list {
  margin-bottom: 70px;
  > div {
    margin-bottom: 10px;
  }
}
</style>
